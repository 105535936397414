import { EntityList } from "./core/entity-list";
import { Collection } from "./collection.model";
import { CollectionDto } from "./dtos/collection.dto";
import { ContractList } from "./contract-list.model";

export class CollectionList extends EntityList<Collection, CollectionDto> {
  constructor(dtos: CollectionDto[] = []) {
    super(dtos, Collection, { generateId: true });
  }

  getUniqueContracts(): ContractList {
    const contractList: ContractList = new ContractList();

    this.map((collection) =>
      contractList.addItems(collection.contractList.items)
    );

    return contractList;
  }

  getImageByContractId(contractId: string): string | undefined {
    return this.getUniqueContracts().getItemById(contractId)?.imageUrl;
  }
}
