import { DeBankServiceImpl } from "./debank/debank.service.impl";
import { DeBankService } from "./debank/debank.service";
import { EnsService } from "./ens/ens.service";
import { EnsServiceImpl } from "./ens/ens.service.impl";
import { GnosisService } from "./gnosis/gnosis.service";
import { GnosisServiceImpl } from "./gnosis/gnosis.service.impl";
import { OpenSeaServiceImpl } from "./openSea/openSea.service.impl";
import { OpenSeaService } from "./openSea/openSea.service";
import { PoapService } from "./poap/poap.service";
import { PoapServiceImpl } from "./poap/poap.service.impl";
import { AccessControlServiceImpl } from "./access-control/access-control.service.impl";
import { AccessControlService } from "./access-control/access-control.service";
import { CryptoServiceImpl } from "./crypto/crypto.service.impl";
import { CryptoService } from "./crypto/crypto.service";

export class Services {
  accessControlService: AccessControlService = new AccessControlServiceImpl();
  cryptoService: CryptoService;
  deBankService: DeBankService = new DeBankServiceImpl();
  ensService: EnsService = new EnsServiceImpl();
  gnosisService: GnosisService = new GnosisServiceImpl();
  openSeaService: OpenSeaService = new OpenSeaServiceImpl();
  poapService: PoapService = new PoapServiceImpl();

  constructor() {
    this.cryptoService = new CryptoServiceImpl(this.accessControlService);
  }
}

export const services = new Services();
