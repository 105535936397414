import { Entity } from "./core/entity";
import { ContractDto } from "./dtos/contract.dto";
import { ERCStandard } from "../types";

export class Contract extends Entity<ContractDto> {
  constructor(dto: ContractDto) {
    super(dto, "address");
  }

  get name(): string {
    return this.dto?.name ?? "";
  }

  get imageUrl(): string {
    return this.dto?.image_url ?? "";
  }

  get standard(): ERCStandard {
    return this.dto?.schema_name;
  }

  isERC721(): boolean {
    return this.standard === ERCStandard.ERC721;
  }
}
