import React from "react";

import "./TeamChannelList.css";
import "../CommunityList/CommunityList.css";

import type { ChannelListMessengerProps } from "stream-chat-react";
import { AddChannel } from "../../assets";
// import chains from "../../assets/img/chains.svg";
// import chevron from "../../assets/img/chevron.svg";
// import eth from "../../assets/img/eth-small.svg";
// import polygon from "../../assets/img/polygon-small.svg";
// import gnosis from "../../assets/img/gnosis-small.svg";

export type TeamChannelListProps = ChannelListMessengerProps & {
  setCreateType: React.Dispatch<React.SetStateAction<string>>;
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
};

const ChannelList: React.FC<TeamChannelListProps> = (props) => {
  // const [showNetworks, setShowNetworks] = useState(false);
  // const [showAll, setShowAll] = useState(true);
  // const [showEth, setShowEth] = useState(false);
  // const [showPoly, setShowPoly] = useState(false);
  // const [showGNO, setShowGNO] = useState(false);

  // useEffect(() => {});

  // const toggleAll = () => {
  //   setShowAll(true);
  //   setShowPoly(false);
  //   setShowGNO(false);
  //   setShowEth(false);
  //   setShowNetworks(!showNetworks);
  // };

  // const toggleEth = () => {
  //   setShowAll(false);
  //   setShowPoly(false);
  //   setShowGNO(false);
  //   setShowEth(true);
  //   setShowNetworks(!showNetworks);
  // };

  // const togglePoly = () => {
  //   setShowAll(false);
  //   setShowPoly(true);
  //   setShowGNO(false);
  //   setShowEth(false);
  //   setShowNetworks(!showNetworks);
  // };

  // const toggleGNO = () => {
  //   setShowAll(false);
  //   setShowPoly(false);
  //   setShowGNO(true);
  //   setShowEth(false);
  //   setShowNetworks(!showNetworks);
  // };

  const {
    children,
    error = false,
    loading,
    setCreateType,
    setIsCreating,
    setIsEditing,
    type,
  } = props;

  // close menu container
  // const menuRef = useRef<HTMLDivElement | null>(null);
  // const btnRef = useRef<HTMLDivElement | null>(null);

  // useEffect(() => {
  //   let handler = (event: { target: any }) => {
  //     if (!menuRef.current?.contains(event.target as any)) {
  //       setShowNetworks(false);
  //     }
  //     if (btnRef.current?.contains(event.target as any)) {
  //       setShowNetworks(!showNetworks);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);
  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // });

  if (error) {
    return type === "team" ? (
      <div className="team-channel-list">
        <p className="team-channel-list__message">
          Connection error, please wait a moment and try again.
        </p>
      </div>
    ) : null;
  }

  if (loading) {
    return (
      <div className="team-channel-list">
        <p className="team-channel-list__message loading">
          {type === "team" ? "Channels" : "Messages"} loading....
        </p>
      </div>
    );
  }

  const isTeam = type === "team";

  return (
    <div className="team-channel-list">
      <div className="team-channel-list__header">
        <p className="team-channel-list__header__title">
          {isTeam ? "My Communities" : "Direct Messages"}
        </p>
        {/* {type === "team" ? (
          <div ref={btnRef} className="my-communities-toggle-btn">
            {showAll && (
              <div className="my-communities-toggle-btn">
                <img src={chains} alt="" />
                <p className="all-networks">All Chains</p>
                <img src={chevron} alt="chevron" />
              </div>
            )}
            {showEth && (
              <div className="my-communities-toggle-btn">
                <img src={eth} alt="" />
                <p className="all-networks">Ethereum</p>
                <img src={chevron} alt="chevron" />
              </div>
            )}
            {showPoly && (
              <div className="my-communities-toggle-btn">
                <img src={polygon} alt="" />
                <p className="all-networks">Polygon</p>
                <img src={chevron} alt="chevron" />
              </div>
            )}
            {showGNO && (
              <div className="my-communities-toggle-btn">
                <img src={gnosis} alt="" />
                <p className="all-networks">Gnosis</p>
                <img src={chevron} alt="chevron" />
              </div>
            )}
          </div>
        ) : (
          ""
        )} */}

        {!isTeam && (
          <AddChannel
            {...{ setCreateType, setIsCreating, setIsEditing }}
            // removed ternary operator to stricly display type={"team"} instead of type={"messaging"}
            type={"messaging"}
          />
        )}
      </div>
      {/* {showNetworks && (
        <div ref={menuRef} className="network-list-container">
          <div ref={menuRef} onClick={toggleEth} className="network-item">
            <img src={eth} alt="eth" />
            <p>Ethereum</p>
          </div>
          <div ref={menuRef} onClick={togglePoly} className="network-item">
            <img src={polygon} alt="polygon" />
            <p>Polygon</p>
          </div>
          <div ref={menuRef} onClick={toggleGNO} className="network-item">
            <img src={gnosis} alt="gnosis" />
            <p>Gnosis</p>
          </div>
          <div ref={menuRef} onClick={toggleAll} className="network-item">
            <img src={chains} alt="chain" />
            <p>All Chains</p>
          </div>
        </div>
      )} */}
      <div className="team-channel-list-container">{children}</div>
    </div>
  );
};

export const TeamChannelList = React.memo(ChannelList);
