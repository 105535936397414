import { AccountId, AssetType } from "caip";
import { DeBankService } from "./debank.service";
import { ServiceResponse } from "../service-response";
import { ApiServiceImpl } from "../core/api.service.impl";
import { UserNftList, UserTokenList } from "../../models";
import { assetIdToChannelId } from "../../lib/utils";
import { ChainApi } from "debank-open-api/build/apis/chain-api";
import { UserApi } from "debank-open-api/build/apis/user-api";
import { AxiosResponse } from "axios";
import { Chain } from "debank-open-api/build/models";
import { UserTokenDto } from "../../models/dtos/user-token.dto";
import { UserNftDto } from "../../models/dtos/user-nft.dto";
// import { ChainID } from "../../types";

const DeBankApi = require("debank-open-api");

export class DeBankServiceImpl extends ApiServiceImpl implements DeBankService {
  static namespaces = {
    NFT: "erc721",
    TOKEN: "erc20",
  };
  private readonly userApi: UserApi = new DeBankApi.UserApi();
  private readonly chainApi: ChainApi = new DeBankApi.ChainApi();

  async getUserTokenList(
    id: string,
    chainId?: string,
    isAll: boolean = false,
    hasBalance: boolean = true
  ): Promise<ServiceResponse<UserTokenList>> {
    try {
      // @ts-ignore
      const response: AxiosResponse<UserTokenDto[]> =
        await this.userApi.getUserTokenList(id, chainId, isAll, hasBalance);

      const filterNativeTokens = response.data.filter(
        (token: { id: string }) => !["eth", "matic", "xdai"].includes(token.id)
      );

      return this.success<UserTokenList>(new UserTokenList(filterNativeTokens));
    } catch (e) {
      return this.error<UserTokenList>(e);
    }
  }

  async getUserNftList(
    id: string,
    chainId: string
  ): Promise<ServiceResponse<UserNftList>> {
    try {
      // @ts-ignore
      const response: AxiosResponse<UserNftDto[]> =
        await this.userApi.getUserNftList(id, chainId);

      return this.success<UserNftList>(new UserNftList(response.data));
    } catch (e) {
      return this.error<UserNftList>(e);
    }
  }

  async loadTokens(
    accountId: AccountId,
    channelIds: string[]
  ): Promise<ServiceResponse<UserTokenList>> {
    try {
      // Load chain list
      const chainListResponse: AxiosResponse<Chain[], any> =
        await this.chainApi.getChainList();

      // Find chain with id same as user's chain Id
      const deBankChain = chainListResponse.data.find(
        (v: any) => v.community_id === Number(accountId.chainId.reference)
      );

      // Get token list of user on active chain
      const response = await this.getUserTokenList(
        accountId.address,
        deBankChain!.id
      );

      const userTokenList = response.data ?? new UserTokenList();

      const filteredTokens = userTokenList.filter((userToken) => {
        const assetId = new AssetType({
          chainId: accountId.chainId,
          assetName: {
            namespace: DeBankServiceImpl.namespaces.TOKEN,
            reference: userToken.id.toLowerCase(),
          },
        });
        const channelId = assetIdToChannelId(assetId);
        return !channelIds.includes(channelId);
      });

      return this.success<UserTokenList>(
        new UserTokenList().addItems(filteredTokens) as UserTokenList
      );
    } catch (e) {
      return this.error<UserTokenList>(e);
    }
  }

  /**
   * @param accountId
   * @param channelIds
   * @deprecated Use opensea method instead. Also delete UserNft and UserNftList models
   */
  async loadNFTs(
    accountId: AccountId,
    channelIds: string[]
  ): Promise<ServiceResponse<UserNftList>> {
    try {

      // Get token list of user on active chain
      const response = await this.getUserNftList(
        accountId.address,
        "eth"
      );

      const userNftList = response?.data ?? new UserNftList();

      const filteredNfts = userNftList.filter((nft) => {
        const assetId = new AssetType({
          chainId: "xdai", // nft.chain,
          assetName: {
            namespace: DeBankServiceImpl.namespaces.NFT,
            reference: nft.id.toLowerCase(),
          },
        });
        const channelId = assetIdToChannelId(assetId);
        return !channelIds.includes(channelId);
      });

      return this.success<UserNftList>(
        new UserNftList().addItems(filteredNfts) as UserNftList
      );
    } catch (e) {
      return this.error<UserNftList>(e);
    }
  }
}
