import React from "react";
import Info from "../../../../assets/img/info.svg";
import { TransactionsContainerProps } from "./transactions.container.props";

export const Transactions = ({
  transaction,
  activeTransaction,
  setActiveTransaction,
  safeId,
}: TransactionsContainerProps) => {
  return (
    <div className="tx-table-row">
      <div
        className="row clickable"
        onClick={() => setActiveTransaction(transaction)}
        title={transaction.id}
      >
        <img height={20} width={20} src={Info} alt="" />
        <b>&nbsp;{transaction.getShortId()}</b>
      </div>
      <div>{transaction.submissionDate}</div>
      <div>{transaction.nonce}</div>
      {/*<div>*/}
      {/*  {transaction.confirmationsReceived} of{" "}*/}
      {/*  {transaction.confirmationsRequired}*/}
      {/*</div>*/}
      {activeTransaction?.id === transaction.id && (
        <>
          <div
            className="tx-detail-modal"
            onClick={() => setActiveTransaction(undefined)}
          >
            <div
              className="tx-detail-modal-card"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <section>
                <h3>Safe Address</h3>
                <h6>{safeId}</h6>
              </section>
              <section>
                <h3>Transaction Type</h3>
                <h6>{safeId}</h6>
              </section>
              <section>
                <h3>Transaction Details</h3>
                <h6 title={transaction.id}>{transaction.getShortId()}</h6>
              </section>
              <section>
                <h3>Transaction Created</h3>
                <h6 title={transaction.id}>{transaction.submissionDate}</h6>
              </section>
              <section>
                <h3>Confirmations</h3>
                {transaction?.confirmationList.map((confirmation) => (
                  <h6 key={confirmation.id} title={confirmation.id}>
                    {confirmation.getShortId()}
                  </h6>
                ))}
              </section>{" "}
              {/*<a*/}
              {/*  href={`https://gnosis-safe.io/app/${chainId}:${safe?.id}/home`}*/}
              {/*  target="blank"*/}
              {/*  className="gnosis-safe-link"*/}
              {/*>*/}
              {/*  View Safe*/}
              {/*</a>*/}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
