import { ethers } from "ethers";
import { AccountId } from "caip";
import { ApiServiceImpl } from "../core/api.service.impl";
import { GnosisService, TransactionReqParams } from "./gnosis.service";
import { ServiceResponse } from "../service-response";
import { GnosisSafeList, TransactionList } from "../../models";
import { TransactionsDto } from "../../models/gnosis/dtos/transactions.dto";
import Web3 from "web3";
import { stores } from "../../stores";

export class GnosisServiceImpl extends ApiServiceImpl implements GnosisService {
  async loadAccountSafes(
    accountId: AccountId,
    channelIds: string[]
  ): Promise<ServiceResponse<GnosisSafeList>> {
    try {
      const checksumAddress = ethers.utils.getAddress(accountId.address);
      const response = await this.get<{ safes: string[] }>(
        `${this.urls.gnosisChains}/${accountId.chainId.reference}/owners/${checksumAddress}/safes`
      );
      const safes: string[] = response.data?.safes ?? [];
      const gnosisSafeList = new GnosisSafeList({
        data: safes,
        accountId,
        channelIds,
      });

      return this.success<GnosisSafeList>(gnosisSafeList);
    } catch (e) {
      return this.error<GnosisSafeList>(e);
    }
  }

  async getTransactions(
    safe: string,
    options?: TransactionReqParams
  ): Promise<ServiceResponse<TransactionList>> {
    try {
      safe = Web3.utils.toChecksumAddress(safe);
      const response = await this.get<TransactionsDto>(
        `${this.urls.gnosisSafes}/${safe}/transactions`,
        options
      );
      const transactionList = new TransactionList(response.data);
      /**
       * To prevent displaying transactions with repeated nonce
       */
      transactionList.removeDuplicateNonce();
      stores.chatStore.activeSafeTransactionList.replace(transactionList);

      return this.success<TransactionList>(transactionList);
    } catch (e) {
      return this.error<TransactionList>(e);
    }
  }
}
