import { Entity } from "./core/entity";
import { PoapDto } from "./dtos/poap.dto";
import { ChannelSourceType } from "../types";
import { UserAccount } from "./UserAccount";

export class Poap extends Entity<PoapDto> {
  readonly poapAccountId: UserAccount;

  static getAccountId(channelId: string): UserAccount {
    return new UserAccount(
      UserAccount.parse(
        channelId.replace(ChannelSourceType.POAP, "").replaceAll("_", ":")
      )
    );
  }

  constructor(dto: PoapDto) {
    super(dto, "event.id");

    this.poapAccountId = new UserAccount({
      chainId: {
        namespace: "eip155",
        reference: dto.chain === "xdai" ? "100" : dto.chain,
      },
      address: dto.event.id.toString(),
    });
  }

  public get event(): PoapDto["event"] {
    return this.dto?.event ?? {};
  }

  get name(): string {
    return this.event.name ?? "";
  }

  get country(): string {
    return this.event.country ?? "";
  }

  get city(): string {
    return this.event.city ?? "";
  }

  get year(): number {
    return this.event.year ?? 0;
  }

  get owner(): string {
    return this.dto?.owner ?? "";
  }
  get chain(): string {
    return this.dto?.chain ?? "";
  }
}
