import { Entity } from "../core/entity";
import { GnosisSafeDto } from "./dtos/gnosis-safe.dto";
import { ChannelSourceType } from "../../types/chat";
import { UserAccount } from "../UserAccount";

export class GnosisSafe extends Entity<GnosisSafeDto> {
  readonly safeAccountId: UserAccount;

  static getAccountId(channelId: string): UserAccount {
    return new UserAccount(
      UserAccount.parse(
        channelId
          .replace(ChannelSourceType.SAFE, "")
          .replace("gno_", "")
          .replaceAll("_", ":")
      )
    );
  }

  constructor(dto: GnosisSafeDto) {
    super(dto, "safe");

    this.safeAccountId = new UserAccount({
      chainId: dto?.accountId?.chainId,
      address: dto?.safe?.toLowerCase(),
    });
  }

  get chainIdNamespace(): string {
    return this.safeAccountId.chainId.namespace ?? "";
  }

  get chainIdReference(): string {
    return this.safeAccountId.chainId.reference ?? "";
  }

  get address(): string {
    return this.safeAccountId.address ?? "";
  }

  get displayName(): string {
    return `safe:${this.safeAccountId.address?.slice(0, 10)}`;
  }
}
