import {
  createJWE,
  decryptJWE,
  JWE,
  xc20pDirDecrypter,
  xc20pDirEncrypter,
} from "did-jwt";
import { decodeCleartext, prepareCleartext } from "dag-jose-utils";
import { decodeBase64, Message } from "stream-chat";
import { accountIdFromUserId } from "../../lib/utils";
import { UserAccount, Channel } from "../../models";
import LitJsSdk from "lit-js-sdk";
import { AccessControlService } from "../access-control/access-control.service";
import { base58btc } from "multiformats/bases/base58";
import { CryptoService } from "./crypto.service";
import { ServiceResponse } from "../service-response";
import { ApiServiceImpl } from "../core/api.service.impl";

export class CryptoServiceImpl extends ApiServiceImpl implements CryptoService {
  constructor(private accessControlService: AccessControlService) {
    super();
  }

  private async saveKeyForKid(kid: string, symmetricKey: Uint8Array) {
    localStorage.setItem(
      `hashchat:keys:${kid}`,
      base58btc.encode(symmetricKey).toString()
    );
  }

  private async fetchKeyFromKid(
    accountId: UserAccount,
    channel: Channel,
    profileTokenId: string,
    message: JWE
  ) {
    const protectedHeader = JSON.parse(decodeBase64(message.protected));
    const decodedKey = base58btc.decode(protectedHeader.kid);
    const symmetricKey = await this.getOrFetchKeyForKid(
      accountId,
      channel,
      profileTokenId,
      protectedHeader.kid
    );

    return {
      kid: protectedHeader.kid,
      encryptedSymmetricKey: decodedKey,
      symmetricKey: symmetricKey,
    };
  }

  private async getOrFetchKeyForKid(
    accountId: UserAccount,
    channel: Channel,
    profileTokenId: string,
    kid: string
  ) {
    const encodedKey = localStorage.getItem(`hashchat:keys:${kid}`);
    if (encodedKey) {
      return base58btc.decode(encodedKey);
    } else {
      const litClient = new LitJsSdk.LitNodeClient();
      await litClient.connect();

      const authSig = await LitJsSdk.checkAndSignAuthMessage({
        chain: accountId.getChain(),
      });
      const conditions = (
        await this.accessControlService.generateAccessControlConditions(
          accountId,
          channel,
          profileTokenId
        )
      ).data;

      const decodedKid = base58btc.decode(kid);
      const symmetricKey: Uint8Array = await litClient.getEncryptionKey({
        ...conditions,
        toDecrypt: LitJsSdk.uint8arrayToString(decodedKid, "base16"),
        chain: accountId.getChain(),
        authSig,
      });
      this.saveKeyForKid(kid, symmetricKey);

      return symmetricKey;
    }
  }

  private async fetchOrCreateChannelKey(
    accountId: UserAccount,
    channel: Channel,
    profileTokenId: string
  ) {
    let channelKey = localStorage.getItem(`hashchat:kids:${channel.id}`);
    const litClient = new LitJsSdk.LitNodeClient();
    await litClient.connect();

    const authSig = await LitJsSdk.checkAndSignAuthMessage({
      chain: accountId.getChain(),
    });

    const conditions = (
      await this.accessControlService.generateAccessControlConditions(
        accountId,
        channel,
        profileTokenId
      )
    ).data;

    if (channelKey == null) {
      const { symmetricKey } = await LitJsSdk.encryptString("");
      const encryptedSymmetricKey = await litClient.saveEncryptionKey({
        ...conditions,
        symmetricKey,
        authSig,
        chain: accountId.getChain(),
      });

      const encodedKey = base58btc.encode(encryptedSymmetricKey).toString();
      localStorage.setItem(`hashchat:kids:${channel.id}`, encodedKey);

      this.saveKeyForKid(encodedKey, symmetricKey);

      return {
        kid: encodedKey,
        encryptedSymmetricKey: encryptedSymmetricKey,
        symmetricKey: symmetricKey,
      };
    } else {
      const symmetricKey = await this.getOrFetchKeyForKid(
        accountId,
        channel,
        profileTokenId,
        channelKey
      );
      const decodedKey = base58btc.decode(channelKey);

      return {
        kid: channelKey,
        encryptedSymmetricKey: decodedKey,
        symmetricKey: symmetricKey,
      };
    }
  }

  async encrypt(
    msg: Record<string, any>,
    kid: string,
    key: Uint8Array
  ): Promise<ServiceResponse<JWE>> {
    const dirEncrypter = xc20pDirEncrypter(key);
    const cleartext = await prepareCleartext(msg);
    const data = await createJWE(cleartext, [dirEncrypter], { kid: kid });
    return this.success(data);
  }

  async decrypt(
    msg: JWE,
    key: Uint8Array
  ): Promise<ServiceResponse<Record<string, any>>> {
    const dirDecrypter = xc20pDirDecrypter(key);
    const decryptedData = await decryptJWE(msg, dirDecrypter);
    return this.success(decodeCleartext(decryptedData));
  }

  async encryptMessage(
    userID: string,
    channel: Channel,
    profileTokenId: string,
    message: Message
  ): Promise<ServiceResponse<JWE>> {
    const userAccount = accountIdFromUserId(userID);
    const { kid, symmetricKey } = await this.fetchOrCreateChannelKey(
      userAccount,
      channel,
      profileTokenId
    );

    return this.encrypt(
      { message: JSON.stringify(message) },
      kid,
      symmetricKey
    );
  }

  async decryptMessage(
    userID: string,
    channel: Channel,
    message: JWE
  ): Promise<ServiceResponse<any>> {
    const userAccount = accountIdFromUserId(userID);
    const { symmetricKey } = await this.fetchKeyFromKid(
      userAccount,
      channel,
      "",
      message
    );
    const decryptedMsg = await this.decrypt(message, symmetricKey);

    return this.success(JSON.parse(decryptedMsg.data!.message));
  }
}
