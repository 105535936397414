import { ChatStore } from "./chat.store";
import { ChannelList, TransactionList } from "../../models";
import { makeAutoObservable } from "mobx";

export class ChatStoreImpl implements ChatStore {
  welcomeScreenVisible: boolean = true;
  channelList: ChannelList = new ChannelList();
  activeSafeTransactionList: TransactionList = new TransactionList();

  constructor() {
    makeAutoObservable(this);
  }

  hideWelcomeScreen(): void {
    this.welcomeScreenVisible = false;
  }
}
