import { EntityList } from "./core/entity-list";
import { UserNft } from "./user-nft.model";
import { UserNftDto } from "./dtos/user-nft.dto";
import { ChannelList } from "./channel-list.model";

/**
 * @deprecated Use NftAssetList
 */
export class UserNftList extends EntityList<UserNft> {
  constructor(dtos: UserNftDto[] = []) {
    super(dtos, UserNft);
  }

  findByContractId(contractId: string): UserNft | undefined {
    return this.find((item) => item.contractId === contractId);
  }

  getNftChannels(channelList: ChannelList): UserNft[] {
    return this.filter((userNFT) => !channelList.hasNftChannel(userNFT.id));
  }

  removeWithChannelId(cid: string): UserNftList {
    const nfts = this.filter((nft) => nft.id === cid.split("_").pop());

    return nfts[0] ? (this.removeItem(nfts[0].id) as UserNftList) : this;
  }
}
