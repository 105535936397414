import { EntityList } from "./core/entity-list";
import { Contract } from "./contract.model";
import { ContractDto } from "./dtos/contract.dto";
import { ChannelList } from "./channel-list.model";

export class ContractList extends EntityList<Contract> {
  constructor(dtos: ContractDto[] = []) {
    super(dtos, Contract);
  }

  getContractChannels(channelList: ChannelList): Contract[] {
    return this.filter((contract) => !channelList.hasNftChannel(contract.id));
  }

  removeWithChannelId(cid: string): ContractList {
    const contracts = this.filter(
      (contract) => contract.id === cid.split("_").pop()
    );

    return contracts[0]
      ? (this.removeItem(contracts[0].id) as ContractList)
      : this;
  }

  filterByERC721(): ContractList {
    const contracts = this.filter((contract) => contract.isERC721());
    return new ContractList().addItems(contracts) as ContractList;
  }
}
