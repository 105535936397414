import { AssetType } from "caip";
import { profiles } from "./get-profiles";
import { UserAccount } from "../models";

export async function getWalletfromLensHandle(handle: string) {
  const profile = await profiles({
    handles: [handle],
  });
  console.log("profile in getWalletfromLensHandle: ", profile);

  return profile.profiles.items[0].ownedBy.toLowerCase();
}

export function assetIdToChannelId(assetId: AssetType) {
  return assetId.toString().replaceAll(":", "_").replaceAll("/", "-");
}

export function assetIdFromChannelId(channelId: string) {
  return new AssetType(channelId.replaceAll("_", ":").replaceAll("-", "/"));
}

export function accountIdFromUserId(userId: string): UserAccount {
  return new UserAccount(UserAccount.parse(userId.replaceAll("_", ":")));
}

/**
 * @deprecated Use getAccountId() from GnosisSafe Model
 */
export function safeAccountIdFromChannelId(channelId: string): UserAccount {
  return new UserAccount(
    UserAccount.parse(
      channelId.replace("safe_", "").replace("gno_", "").replaceAll("_", ":")
    )
  );
}

export function safeAccountIdToChannelId(accountId: UserAccount) {
  return `safe_${accountId
    .toString()
    .replaceAll(":", "_")
    .replaceAll("/", "-")}`;
}

export function poapAccountIdToChannelId(accountId: UserAccount) {
  return `poap_${accountId
    .toString()
    .replaceAll(":", "_")
    .replaceAll("/", "-")}`;
}

/**
 * @deprecated Use getAccountId() from Poap Model
 */
export function poapAccountIdFromChannelId(channelId: string) {
  return new UserAccount(
    UserAccount.parse(channelId.replace("poap_", "").replaceAll("_", ":"))
  );
}

export function poapEventIdfromChannelId(channelId: string) {
  const matches = channelId.split("_"); // 'poap_eip155_100_541620'
  return matches[3];
}

export function nftAccountIdFromChannelId(
  channelId: string
): UserAccount | undefined {
  // changed to follow the CAIP-74 spec and reverse from getstream channelId format
  const nftAssetId = assetIdFromChannelId(channelId);

  // TODO: Remove the hard-coding and verify the casing of the standard
  if (nftAssetId.assetName.namespace === "erc721") {
    return new UserAccount({
      chainId: nftAssetId.chainId,
      address: nftAssetId.assetName.reference,
    });
  }
}
