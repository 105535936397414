import { Entity } from "../core/entity";
import { TransactionDto } from "./dtos/transaction.dto";
import { ConfirmationList } from "./confirmation-list.model";

export class Transaction extends Entity<TransactionDto> {
  constructor(props: TransactionDto) {
    super(props, "safeTxHash");
  }

  get nonce(): number {
    return this.dto?.nonce;
  }

  get safe(): string {
    return this.dto?.safe ?? "";
  }

  // TODO: Is this total count or remaining count?
  get confirmationsRequired(): number {
    return this.dto?.confirmationsRequired ?? 0;
  }

  get confirmationList(): ConfirmationList {
    return new ConfirmationList(this.dto?.confirmations);
  }

  get confirmationsReceived(): number {
    return this.dto?.confirmations?.length ?? 0;
  }

  get submissionDate(): string {
    return this.dto?.submissionDate ?? "";
  }

  getShortId(): string {
    const item = this.id.split("_").pop() ?? "";

    if (item.length <= 10) {
      return item;
    }

    return item.slice(0, 5) + "..." + item.slice(-5);
  }

  isExecuted(): boolean {
    return !!this.dto?.isExecuted;
  }

  isQueued(): boolean {
    return !this.isExecuted();
  }
}
