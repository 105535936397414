import React, { useEffect, useState } from "react";
import { ChannelFilters, ChannelSort, StreamChat } from "stream-chat";
import {
  ChannelStateProvider,
  Chat,
  enTranslations,
  Streami18n,
  TypingProvider,
  useChatContext,
} from "stream-chat-react";

import "stream-chat-react/dist/css/index.css";
import "./App.css";

import { ChannelContainer } from "./components/ChannelContainer/ChannelContainer";
import { ChannelListContainer } from "./components/ChannelListContainer/ChannelListContainer";
// import SideBar from "./components/SideBar/SideBar";
import LitJsSdk from "lit-js-sdk";
import { getLitToken, getUserToken } from "./lib/access";
import { ServicesProvider, StoresProvider, useServices } from "./hooks";
import { stores } from "./stores";
import { ApiServiceImpl, ENV } from "./services";
import { UserAccount } from "./models";
import Logo from "./assets/img/logo.svg";

const urlParams = new URLSearchParams(window.location.search);

const theme = urlParams.get("theme") || "light";
const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    ...enTranslations,
  },
});

// const filters: ChannelFilters[] = [
//   { type: "team", demo: "team" },
//   { type: "messaging", demo: "team" },
// ];

const options = { state: true, watch: true, presence: true, limit: 3 };
const sort: ChannelSort = {
  last_message_at: -1,
  updated_at: -1,
};

const App = () => {
  const { ensService, openSeaService } = useServices();
  const { authStore } = stores;
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  const [createType, setCreateType] = useState("");

  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const handleColorChange = (color: string) => {
      const root = document.documentElement;
      if (color.length && color.length === 7) {
        root.style.setProperty("--primary-color", `${color}E6`);
        root.style.setProperty("--primary-color-alpha", `${color}1A`);
      }
    };

    const login = async () => {
      const { web3, account } = await LitJsSdk.connectWeb3();
      authStore.address = account;
      const resp = await web3.getNetwork();
      const chainId: number = resp.chainId;
      authStore.chainId = chainId;

      let authSigCheck = localStorage.getItem("lit-auth-signature");
      if (authSigCheck && account !== JSON.parse(authSigCheck).address) {
        localStorage.clear();
      }

      const userAccount = new UserAccount({
        chainId: {
          namespace: "eip155",
          reference: chainId.toString(),
        },
        address: account,
      });

      const litToken = await getLitToken(userAccount);

      const { userToken, userId } = await getUserToken(userAccount, litToken);
      authStore.token = userToken;
      authStore.userId = userId;

      const apiKey =
        urlParams.get("apikey") || ApiServiceImpl.getEnv(ENV.STREAM_KEY);
      const client = StreamChat.getInstance(apiKey!, {
        enableInsights: true,
        enableWSFallback: true,
      });

      // Issue 44, 23 and 14 get their start here
      // This is where client and accountId come together
      // needed dedicated infura due to ENS is only on mainnet
      let user_name =
        userAccount.address.slice(0, 5) + "..." + userAccount.address.slice(-5);
      try {
        const ensName = (await ensService.lookupAddress(account)).data;

        if (ensName) {
          authStore.ensName = ensName;
          user_name = ensName.toLocaleLowerCase();
          console.log("ENS name: " + ensName);
        }
      } catch (e) {
        console.log(e);
      }

      authStore.userName = user_name;

      await client.connectUser(
        {
          id: userId,
          name: user_name,
        },
        userToken
      );
      // image: makeBlockie(accountId.address)
      setChatClient(client);

      openSeaService.getCollectionContracts(account);
    };

    if (!chatClient) {
      login();
    }

    window.addEventListener("message", (event) =>
      handleColorChange(event.data)
    );

    return () => {
      chatClient?.disconnectUser();
      window.removeEventListener("message", (event) =>
        handleColorChange(event.data)
      );
    };
  }, [authStore, chatClient, ensService, openSeaService]);

  if (!chatClient)
    return (
      <div className="welcome-screen">
        <img height={60} src={Logo} alt="logo" className="loader-logo" />
        <div>Loading...</div>
      </div>
    );

  const filters: ChannelFilters[] = [
    { type: "team", members: { $in: [chatClient.userID!] } },
    { type: "messaging", members: { $in: [chatClient.userID!] } },
  ];

  return (
    <>
      <div className="app__wrapper">
        <ServicesProvider>
          <StoresProvider>
            <Chat
              client={chatClient}
              {...{ i18nInstance }}
              theme={`team ${theme}`}
            >
              <>
                <ChannelComponent
                  {...{
                    isCreating,
                    filters,
                    options,
                    setCreateType,
                    setIsCreating,
                    setIsEditing,
                    sort,
                    createType,
                    isEditing,
                  }}
                />
                {/*<div>*/}
                {/*  <SideBar />*/}
                {/*</div>*/}
              </>
            </Chat>
          </StoresProvider>
        </ServicesProvider>
      </div>
    </>
  );
};

const ChannelComponent = ({
  isCreating,
  filters,
  options,
  setCreateType,
  setIsCreating,
  setIsEditing,
  sort,
  createType,
  isEditing,
}: any) => {
  const { channel } = useChatContext();

  return (
    <ChannelStateProvider
      value={{
        channel: channel!,
        suppressAutoscroll: true,
        channelCapabilities: {},
        channelConfig: undefined,
        multipleUploads: true,
        notifications: [],
      }}
    >
      <TypingProvider value={{}}>
        <>
          <ChannelListContainer
            {...{
              isCreating,
              filters,
              options,
              setCreateType,
              setIsCreating,
              setIsEditing,
              sort,
            }}
          />
          <ChannelContainer
            {...{
              createType,
              isCreating,
              isEditing,
              setIsCreating,
              setIsEditing,
            }}
          />
        </>
      </TypingProvider>
    </ChannelStateProvider>
  );
};

export default App;
