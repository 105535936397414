import React from "react";
import { TransactionList } from "../../../../models";

export const TransactionsHeader = ({
  transactionList,
  isExecuted,
}: {
  transactionList: TransactionList;
  isExecuted?: boolean;
}) => {
  return (
    <>
      <h4 className="tx-title">{isExecuted ? "Executed" : "Queued"}</h4>
      {transactionList.isEmpty() && (
        <h5 className="list-empty-message">No transactions yet!</h5>
      )}
      {transactionList.hasItems() && (
        <div className="tx-table-row">
          <div>Id</div>
          <div>Submission Date</div>
          <div>Nonce</div>
          {/*<div>Confirmations Required</div>*/}
        </div>
      )}
    </>
  );
};
