import { Entity } from "./core/entity";
import { NftAssetDto } from "./dtos/nftAsset.dto";
import { UserAccount } from "./UserAccount";
import { ChannelSourceType } from "../types";

export class NftAsset extends Entity<NftAssetDto> {
  static getAccountId(channelId: string): UserAccount {
    return new UserAccount(
      UserAccount.parse(
        channelId
          .replace(ChannelSourceType.NFT, "")
          .replace("team:", "")
          .replace("-", "_")
          .replaceAll("_", ":")
      )
    );
  }

  get collectionName(): string {
    return this.dto?.collection?.name ?? "";
  }

  get contractId(): string {
    return this.dto?.asset_contract?.address ?? "";
  }

  get totalSupply(): number {
    return this.dto?.asset_contract?.total_supply ?? 1;
  }

  get tokenId(): string {
    return this.dto?.token_id ?? "";
  }

  get name(): string {
    return this.dto?.name ?? "";
  }

  // TODO: Revisit this. Used to be "dto?.content" in debank.
  get content(): string {
    return this.dto?.image_url ?? "";
  }

  get description(): string {
    return this.dto?.description ?? "";
  }
}
