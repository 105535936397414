import axios, { AxiosResponse } from "axios";
import { ServiceResponse } from "../service-response";

const GNOSIS_BASE_URL = "https://safe-client.gnosis.io/v1";

export enum ENV {
  STREAM_KEY = "REACT_APP_STREAM_KEY",
  POAP_KEY = "REACT_APP_POAP_KEY",
  INFURA_KEY = "REACT_APP_INFURA_KEY",
  NPM_TOKEN = "REACT_APP_NPM_TOKEN",
  ALCHEMY_KEY = "REACT_APP_ALCHEMY_KEY",
  MAGIC_KEY = "REACT_APP_MAGIC_KEY",

}

export class ApiServiceImpl {
  protected readonly urls = {
    /**
     * @see https://safe-transaction.gnosis.io
     */
    gnosisChains: `${GNOSIS_BASE_URL}/chains`,
    gnosisSafes: `https://safe-transaction.polygon.gnosis.io/api/v1/safes`,
    /**
     * @see https://docs.opensea.io/reference/api-overview
     */
    openSeaAsset: `https://api.opensea.io/api/v1/asset`,
    openSeaAssets: `https://api.opensea.io/api/v1/assets`,
    openSeaCollections: `https://api.opensea.io/api/v1/collections?offset=0&limit=300&asset_owner=`,
    /**
     * @see https://documentation.poap.tech/reference/postactionsclaim-delivery-v2-1
     */
    poap: `https://api.poap.tech`,
  };

  static getEnv(env: ENV): string {
    return process.env[env] ?? "";
  }

  success<T>(data: T): ServiceResponse<T> {
    return new ServiceResponse<T>({ data });
  }

  error<T>(error: any): ServiceResponse<T> {
    console.error("Error: ", error);
    return new ServiceResponse<T>({ error });
  }

  get<T>(
    url: string,
    params?: Record<string, any>,
    headers?: Record<string, any>
  ): Promise<AxiosResponse<T>> {
    let urlWithQuery = url;

    if (params) {
      urlWithQuery = urlWithQuery + "?";

      Object.keys(params).map(
        (key, index) =>
          (urlWithQuery = `${urlWithQuery}${index ? "&" : ""}${key}=${
            params[key]
          }`)
      );
    }
    return axios.get<T>(urlWithQuery, headers);
  }
}
