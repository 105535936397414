import _ from "lodash";
import { AccountId } from "caip";
import { EntityList } from "../core/entity-list";
import { GnosisSafe } from "./gnosis-safe.model";
import { GnosisSafeDto } from "./dtos/gnosis-safe.dto";
import { ChannelList } from "../channel-list.model";
import { UserAccount } from "../UserAccount";

export class GnosisSafeList extends EntityList<GnosisSafe> {
  static safeAccountIdToChannelId(userAccount: UserAccount) {
    return `safe_${userAccount
      .toString()
      .replaceAll(":", "_")
      .replaceAll("/", "-")}`;
  }

  constructor(dtos?: {
    data: string[];
    accountId: AccountId;
    channelIds: string[];
  }) {
    const { data = [], accountId, channelIds } = dtos ?? {};

    if (!accountId || !channelIds) {
      super([], GnosisSafe);
      return;
    }
    /**
     * Create a dto object with all the data required for local
     * computation within AccountSafe data model
     */
    const safes = _.map(
      data,
      (safe: string): GnosisSafeDto => ({
        safe,
        accountId,
        channelIds,
      })
    );

    super(safes, GnosisSafe);

    safes.forEach((_safe) => {
      const safe = new GnosisSafe(_safe);
      const channelId = GnosisSafeList.safeAccountIdToChannelId(
        safe.safeAccountId
      );

      if (channelIds.includes(channelId)) {
        return this.removeItem(safe.id);
      }
    });
  }

  getGnosisChannels(channelList: ChannelList): GnosisSafe[] {
    return this.filter(
      (safe) => !channelList.hasGnosisChannel(safe.safeAccountId.address)
    );
  }

  removeWithChannelId(cid: string): GnosisSafeList {
    const safes = this.filter(
      (safe) =>
        safe.safeAccountId.address === cid.split("_").pop()
    );

    return safes[0] ? (this.removeItem(safes[0].id) as GnosisSafeList) : this;
  }
}
