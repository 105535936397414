import { EntityList } from "./core/entity-list";
import { NftAsset } from "./nft-asset.model";
import { NftAssetsDto } from "./dtos/nftAssets.dto";
import { ChannelList } from "./channel-list.model";

export class NftAssetList extends EntityList<NftAsset> {
  previous: NftAssetsDto["previous"];
  next: NftAssetsDto["next"];

  constructor(dto?: NftAssetsDto) {
    super(dto?.assets ?? [], NftAsset);
    this.previous = dto?.previous ?? null;
    this.next = dto?.next ?? null;
  }

  getNftChannels(channelList: ChannelList): NftAsset[] {
    return this.filter((userNFT) => !channelList.hasNftChannel(userNFT.id));
  }

  /**
   * NOTE: For NFT channels, contract address is used for generating cid (item.contractId)
   * Whereas assetId is used for others (item.id)
   *
   * This is because there could be multiple NFTs with different ids that belong
   * to the same collection. But only one channel must exist per collection. Ex: ENS.
   */
  removeWithChannelId(cid: string): NftAssetList {
    const nfts = this.filter((nft) => nft.contractId === cid.split("_").pop());

    return nfts[0] ? (this.removeItem(nfts[0].id) as NftAssetList) : this;
  }
}
