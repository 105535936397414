import { EntityList } from "./core/entity-list";
import { UserToken } from "./user-token.model";
import { UserTokenDto } from "./dtos/user-token.dto";

export class UserTokenList extends EntityList<UserToken> {
  constructor(dtos: UserTokenDto[] = []) {
    super(dtos, UserToken);
  }

  removeWithChannelId(cid: string): UserTokenList {
    const tokens = this.filter((token) => token.id === cid.split("_").pop());

    return tokens[0] ? (this.removeItem(tokens[0].id) as UserTokenList) : this;
  }
}
