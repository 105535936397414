import { useState } from "react";
import { useChatContext } from "stream-chat-react";
import Button from "@mui/material/Button";
// import { AccountId } from "caip";
// import DialogTitle from "@mui/material/DialogTitle";
// import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";

import { getLitToken, upsertUser } from "../../lib/access";
import { accountIdFromUserId, getWalletfromLensHandle } from "../../lib/utils";

import "./CreateChannel.css";

import { UserList } from "./UserList";

import { CloseCreateChannel } from "../../assets";

import type { ChannelFilters } from "stream-chat";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserAccount } from "../../models";
import { useStores } from "../../hooks";
// import { sleep } from "stream-chat/dist/types/utils";

type InputProps = {
  channelName: string;
  setChannelName: (value: React.SetStateAction<string>) => void;
};

const ChannelNameInput: React.FC<InputProps> = (props) => {
  const { channelName = "", setChannelName } = props;
  const handleChange = (event: {
    preventDefault: () => void;
    target: { value: string };
  }) => {
    event.preventDefault();
    setChannelName(event.target.value);
  };

  return (
    <div className="channel-name-input__wrapper">
      <p>Group Chat name (optional)</p>
      <input
        onChange={handleChange}
        placeholder="no-spaces"
        type="text"
        value={channelName}
      />
      <p></p>
    </div>
  );
};

type Props = {
  createType: string;
  filters: ChannelFilters[];
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateChannel: React.FC<Props> = (props) => {
  const { createType, filters, setIsCreating } = props;

  const { client, setActiveChannel } = useChatContext();
  const { chatStore } = useStores();

  const [channelName, setChannelName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<string[] | undefined>([
    client.userID || "",
  ]);
  const [isCreating, setCreating] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [lensHandle, setlensHandle] = useState("");

  const createDMChannel = async () => {
    setCreating(true);
    let _wallet = walletAddress;
    if (lensHandle !== "") {
      _wallet = await getWalletfromLensHandle(lensHandle);
      setWalletAddress(_wallet);
    }
    const senderAccountId = accountIdFromUserId(client.userID!);
    console.log("3wallet: ", walletAddress); //this is also blank?
    const receiverAccountId = new UserAccount({
      chainId: {
        namespace: "eip155",
        reference: senderAccountId.chainId.reference,
      },
      address: _wallet,
    });

    const litToken = await getLitToken(senderAccountId);

    const receiverUserId = await upsertUser(receiverAccountId, litToken);

    try {
      const newChannel = client.channel(createType, channelName, {
        name: channelName,
        members: [receiverUserId, client.userID],
        demo: "team",
      });

      await newChannel.watch();

      setChannelName("");
      setIsCreating(false);
      setSelectedUsers([client.userID || ""]);
      setActiveChannel(newChannel);
      chatStore.hideWelcomeScreen();
    } catch (err) {
      console.log(err);
    }
  };

  const createChannel = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!selectedUsers?.length) return;

    const senderAccountId = accountIdFromUserId(client.userID!);

    // need a token for authentication to add new users or "upsert"
    const litToken = await getLitToken(senderAccountId);

    // ensuring each user that was added is in the db
    selectedUsers.forEach(async (receiverUserId) => {
      await upsertUser(accountIdFromUserId(receiverUserId), litToken);
    });

    try {
      const newChannel = client.channel(createType, channelName, {
        name: channelName,
        members: [...selectedUsers],
        demo: "team",
      });

      await newChannel.watch();

      setChannelName("");
      setIsCreating(false);
      setSelectedUsers([client.userID || ""]);
      setActiveChannel(newChannel);
      chatStore.hideWelcomeScreen();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="create-channel__container">
      <div className="create-channel__header">
        <p>
          {createType === "team"
            ? "Start a new Direct Message or Create a Group"
            : "Send a Direct Message"}
        </p>
        <CloseCreateChannel {...{ setIsCreating }} />
      </div>
      {createType === "team" ? (
        <ChannelNameInput {...{ channelName, setChannelName }} />
      ) : (
        ""
      )}
      <div className="to-address-container">
        <div className="wallet-msg-container">
          <div className="chain-id-container">
            <p className="chain-id">
              Chain ID: {client.clientID?.slice(7, 20).split("_")[0]}
            </p>
          </div>
          <div className="input-btn-container">
            <TextField
              sx={{
                padding: "0px",
                textAlign: "center",
                justifyContent: "center",
              }}
              size="small"
              id="outlined-basic"
              label="Wallet Address"
              variant="outlined"
              className="textfield"
              onChange={(event) => {
                setWalletAddress(event.target.value);
              }}
            />
            <Button
              sx={{
                padding: "7px",
              }}
              className="to-address-btn"
              size="small"
              variant="contained"
              onClick={createDMChannel}
              disabled={isCreating}
              style={{
                backgroundColor: "#00bfff",
                boxShadow: "none",
              }}
            >
              Enter
            </Button>
          </div>
        </div>

        {/* Sending a messaging to a lens profile */}
        <div className="lens-msg-container">
          <p className="chain-id">
            New message to lens handle owner on chain ID:{" "}
            {client.clientID?.slice(7, 20).split("_")[0]}
          </p>
          <div className="lens-input-btn-container">
            <TextField
              sx={{
                padding: "0px",
                textAlign: "center",
                justifyContent: "center",
              }}
              size="small"
              id="outlined-basic"
              label="Lens Handle"
              variant="outlined"
              disabled={isCreating}
              className="textfield"
              onChange={(event) => {
                setlensHandle(event.target.value);
              }}
            />
            <Button
              sx={{
                padding: "7px",
              }}
              className="to-address-btn"
              size="small"
              variant="contained"
              onClick={createDMChannel}
              disabled={isCreating}
              style={{
                backgroundColor: "#00bfff",
                boxShadow: "none",
              }}
            >
              Enter
            </Button>
          </div>
        </div>
      </div>
      <UserList {...{ filters, setSelectedUsers }} />
      <div className="create-channel__button-wrapper" onClick={createChannel}>
        <p>{createType === "team" ? "Create Chat" : "Create Message Group"}</p>
      </div>
    </div>
  );
};
