import React from "react";
import "./WelcomeContainer.css";
import Logo from "../../../../assets/img/logo.svg";
export const WelcomeContainer = () => {
  return (
    <div className="container">
      <div className="welcome-container">
        <div className="welcome-title">
          <p className="welcome-text">Welcome to Hashchat</p>{" "}
        </div>
        <div className="welcome-subtext">&#10024; Announcement board</div>
      </div>
      <div className="announcement-container">
        <img height={40} src={Logo} alt="logo" />
        <div className="announcement-right">
          <p className="announcement-name">sjdthree.eth</p>
          <div className="announcement-text">
            <p>
              Welcome to Hashchat Beta, a better communication tool for crypto!
              Hashchat is end-to-end encrypted to keep your messages safe and
              keep you in control of your data.
            </p>
            <div>
              <p>
                Hashchat Beta currently supports communities of all kind
                including Safes, POAPs, NFTs and more to be announced soon. Chat
                with safe signers, reconnect with an old friend with the same
                POAP, or join an NFT community to discuss all things related to
                the collection.
              </p>
            </div>
            <p>
              Please note, Hashchat is currently in beta, meaning that the
              current release can be unstable and unreliable. If you encounter
              an issue and would like to report it, please click{" "}
              <a
                className="discord-link"
                href="https://discord.com/channels/948588150945624094/1004726815543271455"
              >
                here
              </a>{" "}
              to report your concerns. Because of this, you’re liable to lose
              your chat history under certain conditions as the Hashchat team
              continues development. Note: we recognize the irony of using
              Discord... Hashchat soon!
            </p>
            <p>Happy chatting!</p>
            <p>- Hashchat Team</p>
          </div>
        </div>
      </div>
    </div>
  );
};
