import { UserAccount, Channel, GnosisSafe } from "../../models";
import {
  accountIdFromUserId,
  nftAccountIdFromChannelId,
  poapEventIdfromChannelId,
} from "../../lib/utils";
import { AccessControlService } from "./access-control.service";
import { ServiceResponse } from "../service-response";
import { ApiServiceImpl } from "../core/api.service.impl";
import { Namespace, ERCStandard } from "../../types";

export class AccessControlServiceImpl
  extends ApiServiceImpl
  implements AccessControlService
{

  private async generateGnosisSafeAccessControlConditions(
    safeAccountId: UserAccount
  ) {
    return [
      {
        contractAddress: safeAccountId.address,
        functionName: "isOwner",
        functionAbi: {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
          ],
          name: "isOwner",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        chain: safeAccountId.getChain(),
        functionParams: [":userAddress"],
        returnValueTest: {
          key: "",
          comparator: "=",
          value: "true",
        },
      },
    ];
  }

  /**
   * This requires a Poap Event ID, retrieved by calling the POAP api using the poap token
   * POAPs can be on either xdai or mainnet, so we need an OR condition
   *
   * full documentation is here:
   * https://developer.litprotocol.com/AccessControlConditions/EVM/poap
   *
   * @param poapEventId
   */
  private async generatePoapAccessControlConditions(poapEventId: string) {
    return [
      {
        contractAddress: "0x22C1f6050E56d2876009903609a2cC3fEf83B415",
        standardContractType: ERCStandard.POAP,
        chain: Namespace.XDAI,
        method: "eventId",
        parameters: [],
        returnValueTest: {
          comparator: "=",
          value: poapEventId,
        },
      },
      {
        operator: "or",
      },
      {
        contractAddress: "0x22C1f6050E56d2876009903609a2cC3fEf83B415",
        standardContractType: ERCStandard.POAP,
        chain: Namespace.ETHEREUM,
        method: "eventId",
        parameters: [],
        returnValueTest: {
          comparator: "=",
          value: poapEventId,
        },
      },
    ];
  }

  private generateNFTAccessControlConditions(nftAccountId: UserAccount) {
    return [
      {
        contractAddress: nftAccountId.address,
        standardContractType: ERCStandard.ERC721,
        chain: nftAccountId.getChain(),
        method: "balanceOf",
        parameters: [":userAddress"],
        returnValueTest: {
          comparator: ">",
          value: "0",
        },
      },
    ];
  }

  private async generateDMAccessControlConditions(
    channel: Channel,
    profileTokenId: string | null
  ) {
    const result = await channel.queryMembers({}, { id: -1 });
    const members = result.members;
    let conditions = [];
    for (let i = 0; i < members.length; i++) {
      const member = members[i];
      const userAccount = accountIdFromUserId(member.user_id!);
      let condition;

      if (userAccount.chainId.namespace === "eip155" && !profileTokenId) {
        condition = {
          conditionType: "evmBasic",
          contractAddress: "",
          standardContractType: "",
          chain: userAccount.getChain(),
          method: "",
          parameters: [":userAddress"],
          returnValueTest: {
            comparator: "=",
            value: userAccount.address,
          },
        };
      } else if (userAccount.chainId.namespace === Namespace.SOLANA) {
        condition = {
          method: "",
          params: [":userAddress"],
          chain: Namespace.SOLANA,
          returnValueTest: {
            key: "",
            comparator: "=",
            value: userAccount.address,
          },
        };
      } else if (
        userAccount.chainId.namespace === Namespace.EIP155 &&
        profileTokenId
      ) {
        condition = {
          contractAddress: "0xDb46d1Dc155634FbC732f92E853b10B288AD5a1d",
          standardContractType: ERCStandard.ERC721,
          chain: Namespace.POLYGON,
          method: "ownerOf",
          parameters: [parseInt(profileTokenId, 16).toString(10)],
          returnValueTest: {
            comparator: "=",
            value: userAccount.address,
          },
        };
      } else {
        throw new Error("Unknown chain");
      }

      if (i > 0) {
        conditions.push({ operator: "or" });
      }
      conditions.push(condition);
    }

    return conditions;
  }

  async generateAccessControlConditions(
    accountId: UserAccount,
    channel: Channel,
    profileTokenId: string
  ): Promise<ServiceResponse<Record<string, any>>> {
    let result;

    if (channel.isMessaging()) {
      result = {
        accessControlConditions: await this.generateDMAccessControlConditions(
          channel,
          profileTokenId
        ),
      };
    } else if (channel.isSafe()) {
      result = {
        evmContractConditions:
          await this.generateGnosisSafeAccessControlConditions(
            GnosisSafe.getAccountId(channel.id)
          ),
      };
    } else if (channel.isPoap()) {
      result = {
        accessControlConditions: await this.generatePoapAccessControlConditions(
          poapEventIdfromChannelId(channel.id!)
        ),
      };
    } else if (channel.isNFT()) {
      result = {
        accessControlConditions: this.generateNFTAccessControlConditions(
          nftAccountIdFromChannelId(channel.id)!
        ),
      };
    } else {
      // TODO: This should be removed
      result = {
        accessControlConditions: this.generateNFTAccessControlConditions(
          nftAccountIdFromChannelId(channel.id)!
        ),
      };
    }

    return this.success(result);
  }
}
