import { ApiServiceImpl } from "../core/api.service.impl";
import { ServiceResponse } from "../service-response";
import {
  CollectionList,
  ContractList,
  NftAsset,
  NftAssetList,
} from "../../models";
import { NftAssetsDto } from "../../models/dtos/nftAssets.dto";
import { OpenSeaService } from "./openSea.service";
import { stores } from "../../stores";
import { CollectionDto } from "../../models/dtos/collection.dto";
import { NftAssetDto } from "../../models/dtos/nftAsset.dto";

export class OpenSeaServiceImpl
  extends ApiServiceImpl
  implements OpenSeaService
{
  async getAssets(
    userWalletAddress: string
  ): Promise<ServiceResponse<NftAssetList>> {
    try {
      const response = await this.get<NftAssetsDto>(
        this.urls.openSeaAssets + `?owner=${userWalletAddress}`
      );
      const nftList = new NftAssetList(response.data);
      stores.accountStore.nftAssetList.replace(nftList);

      return this.success<NftAssetList>(nftList);
    } catch (e) {
      return this.error<NftAssetList>(e);
    }
  }

  async getAsset(
    assetContractAddress: string,
    tokenId: string
  ): Promise<ServiceResponse<NftAsset>> {
    try {
      const response = await this.get<NftAssetDto>(
        this.urls.openSeaAsset +
          `/${assetContractAddress}/${tokenId}/?include_orders=false`
      );

      return this.success<NftAsset>(new NftAsset(response.data));
    } catch (e) {
      return this.error<NftAsset>(e);
    }
  }

  async getCollections(
    userWalletAddress: string
  ): Promise<ServiceResponse<CollectionList>> {
    try {
      const response = await this.get<CollectionDto[]>(
        this.urls.openSeaCollections + userWalletAddress
      );

      stores.accountStore.collectionList.replace(
        new CollectionList(response.data)
      );

      return this.success<CollectionList>(new CollectionList(response.data));
    } catch (e) {
      return this.error<CollectionList>(e);
    }
  }

  async getCollectionContracts(
    userWalletAddress: string
  ): Promise<ServiceResponse<ContractList>> {
    try {
      const response = await this.getCollections(userWalletAddress);
      const erc721ContractList =
        response.data?.getUniqueContracts().filterByERC721() ??
        new ContractList();
      stores.accountStore.contractList.replace(erc721ContractList);

      return this.success<ContractList>(erc721ContractList);
    } catch (e) {
      return this.error<ContractList>(e);
    }
  }
}
