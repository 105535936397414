import { StreamMessage } from "stream-chat-react";
import { Entity } from "./core/entity";
import { JWE } from "did-jwt";

enum Type {
  REGULAR = "regular",
  DELETED = "deleted",
}

export class ChatMessage extends Entity<StreamMessage> {
  get userId(): string {
    return this.dto?.user?.id ?? "";
  }

  get userName(): string {
    return this.dto?.user?.name ?? "";
  }

  get userImage(): string | undefined {
    return this.dto?.user?.image;
  }

  get jwe(): JWE {
    return this.dto?.jwe as JWE;
  }

  isDeleted(): boolean {
    return this.dto?.type === Type.DELETED;
  }

  /**
   * Returns message sender name. If not present, defaults to user id.
   */
  getUserName(): string {
    return this.userName || this.id;
  }
}
