import { EntityList } from "../core/entity-list";
import { Transaction } from "./transaction.model";
import { TransactionsDto } from "./dtos/transactions.dto";
import _ from "lodash";

export class TransactionList extends EntityList<Transaction> {
  count: number = 0;
  next = null;
  previous = null;

  constructor(dto?: TransactionsDto) {
    super(dto?.results ?? [], Transaction);
    this.count = dto?.count ?? 0;
    this.previous = dto?.previous ?? null;
    this.next = dto?.next ?? null;

    // Sort by nonce
    this.orderByKey("nonce");
  }

  getExecuted(): TransactionList {
    const list = new TransactionList();
    const items = this.filter((transaction) => transaction.isExecuted());
    list.addItems(items);
    return list;
  }

  getQueued(): TransactionList {
    const list = new TransactionList();
    const items = this.filter((transaction) => transaction.isQueued());
    list.addItems(items);
    return list;
  }

  /**
   * Pass the new transaction list and this function will return true if
   * new transactions are added to the queue
   * @param list
   */
  hasNewQueued(list: TransactionList): boolean {
    return _.some(
      list.items,
      (transaction) =>
        !this.getItemById(transaction.id) && transaction.isQueued()
    );
  }

  /**
   * Transactions repeating with same nonce are removed
   */
  removeDuplicateNonce() {
    const list = new TransactionList();
    const group = _.groupBy(this.items, (item) => item.nonce);

    _.each(group, (items) =>
      items.length > 1 ? list.addItems(items.slice(1)) : undefined
    );

    list.map((item) => this.removeItem(item.id));
  }
}
