import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import type { Channel, ChannelFilters } from "stream-chat";
import {
  ChannelList,
  ChannelListProps,
  useChatContext,
} from "stream-chat-react";

import { ChannelList as ChannelListModel } from "../../models";
import "./ChannelListContainer.css";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TeamChannelList } from "../TeamChannelList/TeamChannelList";
import { TeamChannelPreview } from "../TeamChannelPreview/TeamChannelPreview";
import Logo from "../../assets/img/logo.svg";
import Shield from "../../assets/img/shield.svg";
import { CommunityList } from "../CommunityList/CommunityList";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { useStores } from "../../hooks";

type Props = Omit<ChannelListProps, "filters"> & {
  setCreateType: React.Dispatch<React.SetStateAction<string>>;
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  filters: ChannelFilters[];
};

const CompanyHeader = ({ userID }: { userID: string }) => {
  const { chatStore } = useStores();
  return (
    <div
      onClick={() => (chatStore.welcomeScreenVisible = true)}
      className="channel-list__header"
    >
      <div className="channel-list_header-logo">
        <img className="logo" src={Logo} alt="" />
      </div>
      <div className="channel-list_header-main">
        <p className="channel-list__header__text">
          Hashchat <span className="app-version">v2.1.2</span>
        </p>

        <div className="channel-list__header__crypt">
          <img className="shield" src={Shield} alt="shield" />
          <p>End-to-end encrypted</p>
        </div>
      </div>
    </div>
  );
};

const customChannelTeamFilter = (
  communities: Channel<DefaultStreamChatGenerics>[]
) => {
  return communities.filter((channel) => channel.type === "team");
};

const customChannelMessagingFilter = (channels: Channel[]) => {
  return channels.filter((channel) => channel.type === "messaging");
};

export const ChannelListContainer: React.FC<Props> = observer((props) => {
  const { filters, options, setCreateType, setIsCreating, setIsEditing, sort } =
    props;
  const { accountStore, chatStore } = useStores();
  const { channelList } = chatStore;
  const { contractList, userTokenList, gnosisSafeList, poapList } =
    accountStore;
  const { client } = useChatContext();

  useEffect(() => {
    channelList.discardInvalidChannels();
  }, [channelList, channelList.id]);

  /**
   * TODO: There is an issue with re-rendering when an observable array is updated.
   * MobX is not re-rendering this component because channelList is not
   * directly rendered by this component.
   */
  const [refresh, setRefresh] = useState(false);

  const loadChannels = useCallback(async () => {
    if (client.userID == null) return;

    try {
      // eslint-disable-next-line
      const channels = await client.queryChannels(
        { members: { $in: [client.userID!] } },
        [],
        {
          watch: false,
          state: true,
        }
      );

      // Stores all channels to chat store
      channelList.replace(new ChannelListModel(channels));
      /**
       * Note: This is a temporary solution to rerender until
       * MobX array re-render issue is resolved
       */
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line
  }, [
    client,
    userTokenList.id,
    contractList.id,
    gnosisSafeList.id,
    poapList.id,
  ]);

  useEffect(() => {
    // Refresh active channels if the user tries to join any new channel
    loadChannels();
  }, [
    client,
    loadChannels,
    userTokenList.id,
    contractList.id,
    gnosisSafeList.id,
    poapList.id,
  ]);

  return (
    <div className="channel-list__container">
      <div className="channel-list__list__wrapper">
        <CompanyHeader userID={client.userID!} />

        {/* <ChannelSearch /> */}
        <ChannelList
          // channelRenderFilterFn = {(channels: Channel[]) => {}}
          channelRenderFilterFn={() => {
            return customChannelTeamFilter(channelList.getDtos());
          }}
          filters={filters[0]}
          options={options}
          sort={sort}
          List={(listProps) => (
            <TeamChannelList
              {...listProps}
              {...{ setCreateType, setIsCreating, setIsEditing }}
              type="team"
            />
          )}
          Preview={(previewProps) => (
            <TeamChannelPreview
              {...previewProps}
              {...{ setIsCreating, setIsEditing }}
              type="team"
              loadChannels={loadChannels}
            />
          )}
        />

        <ChannelList
          channelRenderFilterFn={customChannelMessagingFilter}
          filters={filters[1]}
          options={options}
          setActiveChannelOnMount={false}
          sort={sort}
          List={(listProps) => (
            <TeamChannelList
              {...listProps}
              {...{ setCreateType, setIsCreating, setIsEditing }}
              type="messaging"
            />
          )}
          Preview={(previewProps) => (
            <TeamChannelPreview
              {...previewProps}
              {...{ setIsCreating, setIsEditing }}
              type="messaging"
              loadChannels={loadChannels}
            />
          )}
        />
        <CommunityList />
      </div>
    </div>
  );
});
