import { AuthStore } from "./auth.store";
import { makeAutoObservable } from "mobx";

export class AuthStoreImpl implements AuthStore {
  address: string = "";
  chainId: number | null = null;
  token: string = "";
  userId: string = "";
  userName: string = "";
  ensName: string = "";

  constructor() {
    makeAutoObservable(this);
  }
}
