import { CollectionDto } from "./dtos/collection.dto";
import _ from "lodash";
import { ContractList } from "./contract-list.model";

export class Collection {
  id: string;

  constructor(private dto: CollectionDto) {
    this.id = _.uniqueId("collection_");
  }

  get contractList(): ContractList {
    return new ContractList(this.dto?.primary_asset_contracts);
  }

  getDto(): CollectionDto {
    return this.dto;
  }
}
