import { AccountId } from "caip";
import axios from "axios";
import LitJsSdk from "lit-js-sdk";
import { UserAccount } from "../models";

const WORKER_ENDPOINT = "https://betav2-worker.hashchat.workers.dev";

export function generateAccessControlCondition(
  accountId: UserAccount,
  account: string
) {
  return [
    {
      contractAddress: "",
      standardContractType: "",
      chain: accountId.getChain(),
      method: "",
      parameters: [":userAddress"],
      returnValueTest: {
        comparator: "=",
        value: account.toLowerCase(),
      },
    },
  ];
}

export function generateResource(accountId: AccountId) {
  return {
    baseUrl: "stream.hashchat.xyz",
    path: `/token/${accountId}`,
    orgId: "",
    role: "",
    extraData: "",
  };
}

export async function getUserToken(
  accountId: AccountId,
  litToken: string
): Promise<any> {
  const result = await axios.post(
    `${WORKER_ENDPOINT}/token/${accountId.toString()}`,
    null,
    {
      headers: {
        "x-lit-token": litToken,
      },
    }
  );
  return {
    userId: result.data["userId"],
    userToken: result.data["token"],
  };
}

export async function getLitToken(accountId: UserAccount) {
  const litChain = accountId.getChain();

  const litClient = new LitJsSdk.LitNodeClient();
  await litClient.connect();

  const authSig = await LitJsSdk.checkAndSignAuthMessage({
    chain: litChain,
  });

  const accessControlConditions = generateAccessControlCondition(
    accountId,
    authSig.address
  );

  const resourceId = generateResource(accountId);

  let litToken;
  try {
    litToken = await litClient.getSignedToken({
      accessControlConditions,
      chain: litChain,
      authSig,
      resourceId,
    });
  } catch (e) {
    await litClient.saveSigningCondition({
      accessControlConditions,
      chain: litChain,
      authSig,
      resourceId,
    });
    litToken = await litClient.getSignedToken({
      accessControlConditions,
      chain: litChain,
      authSig,
      resourceId,
    });
  }
  return litToken;
}

export async function upsertUser(
  newAccountId: AccountId,
  litToken: string
): Promise<any> {
  const result = await axios.post(
    `${WORKER_ENDPOINT}/users/${newAccountId.toString()}`,
    null,
    {
      headers: {
        "x-lit-token": litToken,
      },
    }
  );
  return result.data["userId"];
}
