import { AccountStore } from "./account.store";
import {
  CollectionList,
  ContractList,
  GnosisSafeList,
  NftAssetList,
  PoapList,
  UserTokenList,
} from "../../models";

export class AccountStoreImpl implements AccountStore {
  userTokenList: UserTokenList = new UserTokenList();
  gnosisSafeList: GnosisSafeList = new GnosisSafeList();
  poapList: PoapList = new PoapList();
  nftAssetList: NftAssetList = new NftAssetList();
  collectionList: CollectionList = new CollectionList();
  contractList: ContractList = new ContractList();
}
