import React, { useMemo, useState } from "react";
import {
  Avatar,
  MessageUIComponentProps,
  useChannelStateContext,
  useChatContext,
  useMessageContext,
} from "stream-chat-react";

import "./TeamMessage.css";
import { TeamMessage } from "./TeamMessage";

import Web3 from "web3";
import { Channel, ChatMessage } from "../../models";
import { useServices } from "../../hooks";

type Props = MessageUIComponentProps & {
  setPinsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EncryptedTeamMessage: React.FC<Props> = (props) => {
  const { setPinsOpen } = props;

  const { client } = useChatContext();
  const { cryptoService } = useServices();

  const { channel } = useChannelStateContext();

  const { message: _message } = useMessageContext();

  const message = useMemo(() => new ChatMessage(_message), [_message]);

  const [modal, setModal] = useState(false);

  var web3: Web3 = new Web3();

  const [decryptedMessage, setDecryptedMessage] =
    React.useState<ChatMessage | null>(null);

  React.useEffect(() => {
    const decrypt = async () => {
      if (!message.jwe) {
        setDecryptedMessage(message);
        return;
      }
      const jwe = message.jwe;
      if (!jwe.protected) {
        setDecryptedMessage(message);
        return;
      }

      const _decryptedMessageResponse = await cryptoService.decryptMessage(
        client.userID!,
        new Channel(channel),
        jwe
      );
      setDecryptedMessage(
        new ChatMessage({
          ...message.getDto(),
          ..._decryptedMessageResponse.data,
        })
      );
    };

    decrypt();
  }, [message, channel, client, cryptoService]);

  const ethEnabled = async () => {
    if (typeof window.ethereum !== "undefined") {
      // Instance web3 with the provided information from the MetaMask provider information
      web3 = new Web3(window.ethereum);

      try {
        await web3.eth.requestAccounts();
        return true;
      } catch (error) {
        return false;
      }
    }
  };

  const toggleModal = async () => {
    if (!ethEnabled()) {
      alert("Please install MetaMask to use this dApp!");
    }

    setModal(true);

    web3 = new Web3(window.ethereum);

    // let apiInstance = new DeBankOpenApi.UserApi();
    // let id = accs[0]; // String | Address
    // let chainId = "eth"; // String | ChainID

    // apiInstance.getUserNftList(id, chainId).then(
    //   (response: any) => {
    //     setnft_list(response.data);
    //   },
    //   (error: any) => {
    //     console.error(error);
    //   }
    // );
  };

  const handleClose = () => {
    setModal(false);
  };

  // console.log(message.user.id);
  // console.log(message.user.name);
  // console.log(message.user.image);

  if (decryptedMessage?.isDeleted()) {
    return <></>;
  }

  return decryptedMessage == null ? (
    <div>Decrypting...</div>
  ) : (
    <>
      <TeamMessage
        onUserClick={toggleModal}
        setPinsOpen={setPinsOpen}
        message={decryptedMessage.getDto()}
      />
      {modal && (
        <div className="modal">
          <div className="overlay">
            <div className="modal-content">
              <div className="modal-top">
                <div className="modal-profile-container">
                  <div className="modal-profile-avatar">
                    <Avatar
                      image={message.userImage}
                      name={message.getUserName()}
                      // name={defaultName}
                      size={40}
                    />
                  </div>
                  <div className="modal-profile-name">
                    <h4 className="username">Name: {message.userName}</h4>
                    <h4 className="wallet-address"> ID: {message.userId}</h4>
                  </div>
                </div>
                <div className="dm-container">
                  {/* <button className="dm-btn">Direct Message</button> */}
                </div>
              </div>
              <div className="shared-tokens-container">
                {/* <h3>Shared Communities</h3>
                <p className="shared-token-list">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Asperiores tempora incidunt amet provident impedit! Facere
                  numquam fuga sed aspernatur. Modi cupiditate possimus
                  assumenda dicta? Saepe non ea incidunt molestias odit.
                </p> */}
              </div>
              <button className="close-profile-modal" onClick={handleClose}>
                close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
