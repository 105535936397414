import { PoapService } from "./poap.service";
import { ServiceResponse } from "../service-response";
import { PoapList } from "../../models";
import { stores } from "../../stores";
import { ApiServiceImpl, ENV } from "../core/api.service.impl";
import { PoapDto } from "../../models/dtos/poap.dto";

export class PoapServiceImpl extends ApiServiceImpl implements PoapService {
  async getPoaps(address: string): Promise<ServiceResponse<PoapList>> {
    try {
      const response = await this.get<PoapDto[]>(
        this.urls.poap + `/actions/scan/${address}`,
        undefined,
        {
          "X-API-Key": ApiServiceImpl.getEnv(ENV.POAP_KEY),
        }
      );
      const poapList = new PoapList(response.data);
      stores.accountStore.poapList.replace(poapList);

      return this.success<PoapList>(poapList);
    } catch (e) {
      return this.error<PoapList>(e);
    }
  }
}
