import { Entity } from "./core/entity";
import { UserNftDto } from "./dtos/user-nft.dto";

/**
 * @deprecated NftAsset
 */
export class UserNft extends Entity<UserNftDto> {
  get name(): string {
    return this.dto?.name ?? "";
  }

  get content(): string {
    return this.dto?.content ?? "";
  }

  get description(): string {
    return this.dto?.description ?? "";
  }

  get contractId(): string {
    return this.dto?.contract_id ?? "";
  }

  get totalSupply(): number {
    return this.dto?.total_supply ?? 0;
  }
}
