import { UserTokenDto } from "./dtos/user-token.dto";
import { Entity } from "./core/entity";

export class UserToken extends Entity<UserTokenDto> {
  get name(): string {
    return this.dto?.name ?? "";
  }

  get balance(): string {
    return "0";
    // Disabled temporarily since no such property exists
    // on API response
    // return this.dto?.balance ?? "";
  }

  get price(): number {
    return this.dto?.price ?? 0;
  }

  get symbol(): string {
    return this.dto?.symbol ?? "";
  }

  get logoUrl(): string {
    return this.dto?.logo_url ?? "";
  }
}
