import { EntityList } from "./core/entity-list";
import { Poap } from "./poap.model";
import { PoapDto } from "./dtos/poap.dto";

export class PoapList extends EntityList<Poap> {
  constructor(dtos: PoapDto[] = []) {
    super(dtos, Poap);
  }

  removeWithChannelId(cid: string): PoapList {
    const poaps = this.filter(
      // toString() because only POAPs have numeric ID
      (poap) => poap.id.toString() === cid.split("_").pop()
    );

    return poaps[0] ? (this.removeItem(poaps[0].id) as PoapList) : this;
  }
}
